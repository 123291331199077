import request from '@/utils/request'


// 查询动态标签列表
export function listTag(query) {
  return request({
    url: '/user/dynamic-tags/list',
    method: 'get',
    params: query
  })
}

// 查询动态标签分页
export function pageTag(query) {
  return request({
    url: '/user/dynamic-tags/page',
    method: 'get',
    params: query
  })
}

// 查询动态标签详细
export function getTag(data) {
  return request({
    url: '/user/dynamic-tags/detail',
    method: 'get',
    params: data
  })
}

// 新增动态标签
export function addTag(data) {
  return request({
    url: '/user/dynamic-tags/add',
    method: 'post',
    data: data
  })
}

// 修改动态标签
export function updateTag(data) {
  return request({
    url: '/user/dynamic-tags/edit',
    method: 'post',
    data: data
  })
}

// 删除动态标签
export function delTag(data) {
  return request({
    url: '/user/dynamic-tags/delete',
    method: 'post',
    data: data
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/user/dynamic-tags/edit',
    method: 'post',
    data: data
  })
}
